import { IconProps } from '../type'

const Gecko: React.FC<IconProps> = ({ color = '#fff', width = '.21rem', height ='.21rem' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" style={{ width: width, height: height }}>
      <path d="M18.8582 7.22928L17.7559 6.12695C17.0049 6.73741 15.7093 7.25124 14.8925 7.51914C14.8573 7.53231 14.8222 7.54549 14.7827 7.55866C14.5675 7.76947 14.3567 7.99344 14.1591 8.2306L15.7664 9.83798L18.7748 12.8463L18.8582 12.7629C20.3909 11.2389 20.3909 8.75761 18.8582 7.22928Z" fill="url(#paint0_linear_1751_296)"/>
      <path d="M18.8582 7.22928L17.7559 6.12695C17.0049 6.73741 15.7093 7.25124 14.8925 7.51914C14.8573 7.53231 14.8222 7.54549 14.7827 7.55866C14.5675 7.76947 14.3567 7.99344 14.1591 8.2306L15.7664 9.83798L18.7748 12.8463L18.8582 12.7629C20.3909 11.2389 20.3909 8.75761 18.8582 7.22928Z" fill="url(#paint1_linear_1751_296)"/>
      <path d="M4.23808 9.8419L7.36939 6.71058C7.45284 6.48661 7.54067 6.26263 7.63729 6.03865C8.58152 3.92183 10.6983 3.20597 12.6966 3.17084C12.9601 2.70531 13.4564 2.3935 14.0141 2.38911L12.7712 1.14625C11.2429 -0.382082 8.76158 -0.382082 7.23325 1.14625L7.14981 1.22969L1.22974 7.14976L4.23808 10.1581C4.15025 10.0703 4.15025 9.92973 4.23808 9.8419Z" fill="url(#paint2_linear_1751_296)"/>
      <path d="M4.23808 9.8419L7.36939 6.71058C7.45284 6.48661 7.54067 6.26263 7.63729 6.03865C8.58152 3.92183 10.6983 3.20597 12.6966 3.17084C12.9601 2.70531 13.4564 2.3935 14.0141 2.38911L12.7712 1.14625C11.2429 -0.382082 8.76158 -0.382082 7.23325 1.14625L7.14981 1.22969L1.22974 7.14976L4.23808 10.1581C4.15025 10.0703 4.15025 9.92973 4.23808 9.8419Z" fill="url(#paint3_linear_1751_296)"/>
      <path d="M15.7708 9.84253C15.8586 9.93036 15.8586 10.0709 15.7708 10.1587L12.9996 12.9299L12.3188 13.6106L10.4743 15.4552L10.1625 15.767C10.0747 15.8548 9.93412 15.8548 9.84629 15.767L7.04436 12.9651L5.19982 11.1205L4.23803 10.1587L1.22969 7.15039L1.14625 7.23383C-0.382082 8.76216 -0.382082 11.2435 1.14625 12.7718L1.84014 13.4613L7.07071 18.6919L7.2332 18.8544C8.76153 20.3827 11.2429 20.3827 12.7712 18.8544L12.8546 18.7709L18.7791 12.8465L15.7708 9.84253Z" fill="url(#paint4_linear_1751_296)"/>
      <path d="M15.0592 3.43076C14.9494 3.18922 14.7562 2.9872 14.5015 2.87301C14.4927 2.86862 14.4883 2.86423 14.4795 2.85984C14.4444 2.84666 14.4092 2.83349 14.3785 2.82031C14.3961 2.94328 14.3829 3.07064 14.3346 3.18922C14.3214 3.21996 14.3038 3.24631 14.2863 3.27705C14.115 3.55813 13.7593 3.6767 13.4518 3.54495C13.3069 3.48347 13.1927 3.37367 13.1225 3.24192C13.0785 3.3078 13.0346 3.37806 13.0039 3.45272C12.7579 4.02365 13.0258 4.6868 13.5968 4.93274C14.1677 5.17868 14.8309 4.91078 15.0768 4.33986C15.1998 4.03683 15.1866 3.70744 15.0592 3.43076Z" fill="#1E1E1E"/>
      <path d="M7.38252 6.67578C7.37813 6.68456 7.37374 6.69774 7.36935 6.70652C6.78964 9.77635 6.49978 10.2067 5.19543 11.1158L5.66096 11.5814C5.96399 11.2827 6.39877 10.7645 6.8116 9.96959C7.31665 8.99023 7.38691 7.5146 7.38252 6.67578Z" fill="url(#paint5_linear_1751_296)"/>
      <path d="M13.9876 8.01972C13.9788 8.0285 13.9701 8.0329 13.9613 8.04168C13.6714 8.27444 13.3991 8.57308 13.1576 8.92881C12.4417 10.0004 12.0289 11.599 12.3144 13.6104L13.4079 12.5388C13.3596 12.3807 13.3157 12.2138 13.2762 12.0426C12.8985 10.4484 13.1795 9.39873 14.1589 8.23492C14.2819 8.08999 14.5629 7.80013 14.8133 7.5498C14.8045 7.5542 14.7957 7.55859 14.7869 7.55859C14.5146 7.66399 14.2424 7.8177 13.9876 8.01972Z" fill="url(#paint6_linear_1751_296)"/>
      <path d="M13.162 8.92969C12.4462 10.0013 12.0333 11.5999 12.3144 13.6113C12.0333 11.5955 12.4462 10.0013 13.162 8.92969Z" fill="white"/>
      <path d="M13.162 8.92969C12.4462 10.0013 12.0333 11.5999 12.3144 13.6113C12.0333 11.5955 12.4462 10.0013 13.162 8.92969Z" fill="url(#paint7_linear_1751_296)"/>
      <path d="M18.52 4.61208C17.8788 3.70738 15.9332 3.29017 15.9332 3.29017C15.9332 3.29017 15.6697 2.55675 14.9846 2.32838C14.3654 2.12196 13.4343 2.49087 13.4343 2.49087L13.4475 2.50405C13.6232 2.42939 13.8164 2.38986 14.0184 2.38547C14.0667 2.38547 14.115 2.38547 14.1634 2.38986C14.8353 2.44256 15.3667 2.92126 15.5248 3.5405C18.7879 4.34858 18.7527 5.32355 17.7602 6.13163C17.7207 6.16237 17.6811 6.19311 17.6372 6.22385L17.6548 6.24142C17.6548 6.23703 19.1304 5.48165 18.52 4.61208Z" fill="url(#paint8_linear_1751_296)"/>
      <path d="M15.5204 3.54077C15.3622 2.92593 14.8265 2.44723 14.1589 2.39013C14.1106 2.38574 14.0623 2.38574 14.014 2.38574C13.812 2.39013 13.6187 2.42966 13.4431 2.50432C13.1269 2.63607 12.8633 2.86883 12.6965 3.16747C10.7026 3.20261 8.5814 3.91846 7.63717 6.03528C7.54494 6.24609 7.45711 6.46128 7.37806 6.67648C7.38245 7.5153 7.31218 8.99092 6.80713 9.97028C6.39431 10.7696 5.95952 11.2834 5.65649 11.5821L7.0355 12.9611L9.83743 15.763C9.92527 15.8508 10.0658 15.8508 10.1536 15.763L10.4655 15.4512L12.31 13.6066C12.0245 11.5908 12.4373 9.99224 13.1576 8.92505C13.3947 8.56932 13.6714 8.27068 13.9613 8.03352C13.9701 8.02913 13.9788 8.02035 13.9832 8.01596C14.238 7.81394 14.5059 7.66023 14.7781 7.55482C14.7869 7.55043 14.7957 7.54604 14.8045 7.54604C14.8308 7.53726 14.8572 7.52408 14.8835 7.5153C15.6565 7.26058 16.8642 6.78627 17.624 6.21973C17.6635 6.18899 17.7074 6.15825 17.747 6.12751C18.7483 5.32382 18.7878 4.34885 15.5204 3.54077ZM15.0724 4.34007C14.8265 4.91099 14.1677 5.17889 13.5924 4.93295C13.0214 4.68702 12.7536 4.02825 12.9995 3.45294C13.0302 3.37828 13.0698 3.30801 13.1181 3.24213C13.1883 3.37388 13.3025 3.48368 13.4474 3.54516C13.7593 3.67691 14.1106 3.55834 14.2819 3.27727C14.2863 3.26848 14.2907 3.26409 14.2951 3.25531C14.3082 3.23335 14.3214 3.21139 14.3302 3.18943C14.3829 3.06646 14.3961 2.9391 14.3741 2.82052C14.4092 2.8337 14.4444 2.84248 14.4751 2.86005C14.4839 2.86444 14.4883 2.86883 14.4971 2.87323C14.7518 2.9918 14.945 3.19382 15.0548 3.43098C15.1383 3.61104 15.169 3.81745 15.1471 4.01947C15.1427 4.12926 15.1163 4.23467 15.0724 4.34007Z" fill="white"/>
      <defs>
        <linearGradient id="paint0_linear_1751_296" x1="6.9296" y1="20.2372" x2="20.9728" y2="5.93716" gradientUnits="userSpaceOnUse">
          <stop stop-color="#5D47FF"/>
          <stop offset="0.5903" stop-color="#846DFF"/>
          <stop offset="1" stop-color="#A36CFF"/>
        </linearGradient>
        <linearGradient id="paint1_linear_1751_296" x1="9.45822" y1="17.3641" x2="21.3688" y2="5.72125" gradientUnits="userSpaceOnUse">
          <stop stop-color="#5D47FF"/>
          <stop offset="0.5903" stop-color="#846DFF"/>
          <stop offset="1" stop-color="#A36CFF"/>
        </linearGradient>
        <linearGradient id="paint2_linear_1751_296" x1="-0.451818" y1="12.9879" x2="13.5913" y2="-1.3121" gradientUnits="userSpaceOnUse">
          <stop stop-color="#5D47FF"/>
          <stop offset="0.5903" stop-color="#846DFF"/>
          <stop offset="1" stop-color="#A36CFF"/>
        </linearGradient>
        <linearGradient id="paint3_linear_1751_296" x1="-6.6415" y1="16.7084" x2="17.9825" y2="-4.61455" gradientUnits="userSpaceOnUse">
          <stop stop-color="#5D47FF"/>
          <stop offset="0.5903" stop-color="#846DFF"/>
          <stop offset="1" stop-color="#A36CFF"/>
        </linearGradient>
        <linearGradient id="paint4_linear_1751_296" x1="17.8004" y1="2.10761" x2="2.99024" y2="17.0962" gradientUnits="userSpaceOnUse">
          <stop stop-color="#5D47FF"/>
          <stop offset="0.5903" stop-color="#846DFF"/>
          <stop offset="1" stop-color="#A36CFF"/>
        </linearGradient>
        <linearGradient id="paint5_linear_1751_296" x1="5.19721" y1="9.12915" x2="7.38186" y2="9.12915" gradientUnits="userSpaceOnUse">
          <stop stop-color="#5D47FF"/>
          <stop offset="0.5903" stop-color="#846DFF"/>
          <stop offset="1" stop-color="#A36CFF"/>
        </linearGradient>
        <linearGradient id="paint6_linear_1751_296" x1="-24.7183" y1="129.38" x2="-0.155161" y2="52.4868" gradientUnits="userSpaceOnUse">
          <stop stop-color="#5D47FF"/>
          <stop offset="0.5903" stop-color="#846DFF"/>
          <stop offset="1" stop-color="#A36CFF"/>
        </linearGradient>
        <linearGradient id="paint7_linear_1751_296" x1="-25.0581" y1="129.272" x2="-0.494904" y2="52.3791" gradientUnits="userSpaceOnUse">
          <stop stop-color="#5D47FF"/>
          <stop offset="0.5903" stop-color="#846DFF"/>
          <stop offset="1" stop-color="#A36CFF"/>
        </linearGradient>
        <linearGradient id="paint8_linear_1751_296" x1="13.4313" y1="4.25249" x2="18.667" y2="4.25249" gradientUnits="userSpaceOnUse">
          <stop stop-color="#5D47FF"/>
          <stop offset="0.5903" stop-color="#846DFF"/>
          <stop offset="1" stop-color="#A36CFF"/>
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Gecko
